import React, { FC, useEffect, useMemo, useState } from 'react'
import Slider from 'infinite-react-carousel'
import Image from './Image'

interface Carousel2Props {
  images: string[]
  time?: number
  className?: string
  style?: {}
  itemClassName?: string
  itemStyle?: {}
  height: number
  forwardRef?: any
  slickNext: () => void
  slickPrev: () => void
  slickGoTo0: () => void
}

export const Carousel2: FC<Carousel2Props> = ({
  images,
  time,
  className,
  style,
  itemClassName,
  itemStyle,
  height,
  forwardRef,
  slickNext,
  slickPrev,
  slickGoTo0,
}) => {
  // const [x, setX] = useState(true)
  // useEffect(() => {
  //   setX(!x)
  // }, [])
  //
  // x &&
  //   setTimeout(() => {
  //     slickGoTo0()
  //   }, 500)

  const settings = {
    adaptiveHeight: false,
    arrows: false,
    dots: false,
    autoplay: false,
    autoplaySpeed: time || 4000,
    virtualList: true,
    initialSlide: -1,
  }

  return useMemo(
    () =>
      images.length > 0 ? (
        <Slider
          ref={forwardRef}
          className={`w-full ${className}`}
          {...settings}
        >
          {images.map((image: string, i) => (
            <Image
              key={image}
              className="border-none rounded-t-none"
              resource={image}
              height={height}
            />
          ))}
        </Slider>
      ) : null,
    [images]
  )
}

export default Carousel2
