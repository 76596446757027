import React, { FC, ReactElement } from 'react'

interface CardInterfaceProps {
  header?: ReactElement
  content?: ReactElement
  footer?: ReactElement
  className?: string
}

export const Card: FC<CardInterfaceProps> = ({
  children,
  header,
  content,
  footer,
  className,
}) => (
  <div className={`mb-4 bg-gray-200 p-2 rounded-md text-sm ${className}`}>
    {header && (
      <div className="border-b-2 border-red-A200 border-solid uppercase font-bold text-md">
        {header}
      </div>
    )}
    <div className="pt-2">{content || children}</div>
    {footer}
  </div>
)

export default Card
