import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Home, Map, Menu, NotFound, Product } from 'app/scenes'

export const Main = () => {
  return (
    <Switch>
      <Route
        exact
        path="/commerce/:commerceId/product/:productId"
        component={Product}
      />
      {/*<Route exact path="/commerce/:commerceId/detail" component={Menu} />*/}
      <Route exact path="/commerce/:commerceId" component={Menu} />
      <Route exact path="/map/:commerceId" component={Map} />
      <Route exact path="/" component={Home} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Main
