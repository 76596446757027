import React, { FC } from 'react'
import { RiCloseCircleLine, RiSearchEyeLine } from 'react-icons/ri'

interface SerchBarInterfaceProps {
  placeholder?: string
  value: string
  onChange: (value: string) => void
  onSearch?: (value: string) => void
  LeftIcon?: any
  center?: boolean
  uppercase?: boolean
  className?: string
}

export const SearchBar: FC<SerchBarInterfaceProps> = ({
  placeholder,
  value,
  onChange,
  onSearch,
  LeftIcon,
  uppercase = false,
  center,
  className = '',
}) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  const clearValue = () => onChange('')

  const handleOnSearch = () => onSearch && onSearch(value)

  return (
    <div className="flex flex-stretch overflow-hidden rounded-md border-2 border-solid border-red-A200">
      {LeftIcon && (
        <div className="flex justify-center text-white p-2 uppercase bg-red-A200">
          <LeftIcon className="self-center text-xl" />
        </div>
      )}
      <div className="flex-1">
        <input
          className={`w-full p-2 bg-white focus:outline-none ${
            center ? 'text-center' : ''
          } ${uppercase ? 'uppercase' : ''} ${className}`}
          placeholder={placeholder || ''}
          value={value}
          onChange={handleOnChange}
        />
      </div>
      {value && (
        <button className={`bg-gray-200 px-2`} onClick={() => onChange('')}>
          <RiCloseCircleLine className="text-md" />
        </button>
      )}
      {onSearch && (
        <button
          className="text-white px-2 uppercase bg-gray-200 text-red-A200 hover:text-red-A700 focus:outline-none focus:shadow-outline"
          onClick={handleOnSearch}
        >
          <RiSearchEyeLine className="text-xl" />
        </button>
      )}
    </div>
  )
}

export default SearchBar
