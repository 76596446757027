import React, { useEffect, useState } from 'react'
import { Layout, Navbar, SearchBar } from 'app/components'
import { ContentDirection } from 'app/components/Layout'
import { useSelector } from 'react-redux'
import { isEmpty, useFirestore } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import { FiAlertOctagon } from 'react-icons/fi'
import { useTranslation } from 'app/hooks'
import { RiStore2Line } from 'react-icons/ri/index'

export const Home = () => {
  const [value, setValue] = useState('')
  const [notify, setNotify] = useState(false)
  const firestore = useFirestore()
  const { t } = useTranslation()
  const history = useHistory()
  const commerce = useSelector(
    (state: any) => state.firestore.ordered.comercios
  )

  useEffect(() => {
    isEmpty(commerce)
      ? commerce && setNotify(true)
      : history.push(`/commerce/${commerce[0]?.id}`)
  }, [commerce])

  const onSearch = (search: string) => {
    firestore.get({
      collection: 'comercios',
      where: [
        ['habilitado', '==', true],
        ['friendlyCode', '==', search.toUpperCase()],
      ],
      limit: 1,
    })
  }

  return (
    <Layout
      header={
        <h1 className="text-center text-white uppercase">{t('home.title')}</h1>
      }
      navbar={<Navbar />}
      footer={
        <div className="bg-gray-300 rounded-t-lg text-gray-500">
          <p className="text-xs p-2 text-center">
            {t('menu.footer.copyright')}
          </p>
        </div>
      }
      contentDirection={ContentDirection.row}
    >
      <div className="px-2 relative flex justify-center w-full">
        <div className="w-full md:w-1/3">
          <p className="text-center mb-2">{t('home.formText')}</p>
          {/*<form className="flex flex-col" onSubmit={handleSubmit(onSubmit)}>*/}
          <SearchBar
            placeholder="codigo de local"
            value={value}
            onChange={setValue}
            LeftIcon={RiStore2Line}
            onSearch={onSearch}
            uppercase
            center
            className="max-w-full"
          />
          {notify && (
            <div className="flex items-center bg-red-700 mt-2 p-2 rounded-lg text-white">
              <FiAlertOctagon className="text-2xl" />
              <p className=" flex-1 text-center">{t('home.error')}</p>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Home
