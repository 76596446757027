import React from 'react'
import { ProductList, Collapsible } from 'app/components'
import Fade from 'react-reveal/Fade.js'

export const Category = ({
  commerceId,
  category,
  forceOpen,
}: {
  commerceId: string
  category: any
  forceOpen: boolean
}) => {
  const products = category.productos

  return (
    <Fade left timeout={300}>
      {products && (
        <div className="mb-2">
          <Collapsible
            title={category?.nombre}
            description={category?.descripcion}
            forceOpen={forceOpen}
          >
            <ProductList commerceId={commerceId} products={products} />
          </Collapsible>
        </div>
      )}
    </Fade>
  )
}

export default Category
