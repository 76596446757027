import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  isEmpty,
  isLoaded,
  populate,
  useFirestoreConnect,
} from 'react-redux-firebase'
import { Link, Redirect, useParams } from 'react-router-dom'
import { CategoryList, Commerce, ParallaxLayout, Navbar } from 'app/components'
import { MenuLoader } from 'app/components/loaders'
import { FiMapPin, FiPhone } from 'react-icons/fi'
import { useTranslation } from 'app/hooks'
import {
  RiRestaurantLine,
  RiShareLine,
  RiStore2Line,
  RiWhatsappLine,
} from 'react-icons/ri'
import { Helmet } from 'react-helmet'
import SearchBar from '../components/SearchBar'
import { setSearch, resetSearch } from 'app/store/actions/search/search.actions'

export const Menu = () => {
  const [tab, setTab] = useState(0)
  const [loading, setLoading] = useState(true)
  const { commerceId } = useParams()
  const panelState = useState(false)
  const [panel, setPanel] = panelState
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const value = useSelector(({ search }: { search: any }) => search.value)
  const setValue = (value: string): any => dispatch(setSearch(value))

  const populatesCategories = [{ root: 'productos', child: 'productos' }]

  const commerce = useSelector(({ firestore }: { firestore: any }) => {
    const commerces = firestore.data.comercios
    const categorias = firestore.data.categorias

    return (
      commerces &&
      Object.keys(commerces).map((commerceKey) => {
        const categories = populate(
          firestore,
          'categorias',
          populatesCategories
        )

        return {
          ...commerces[commerceKey],
          categorias: (() => {
            if (categories === undefined) {
              return []
            } else {
              loading && setLoading(false)
              return categories
                ? commerces[commerceKey].categorias
                    .map((categoryKey: string) => {
                      return {
                        ...categories[categoryKey],
                        productos: categorias[categoryKey]?.productos
                          .map((productKey: string) =>
                            categories[categoryKey].productos.find(
                              (product: any) =>
                                product.id === productKey && product.habilitado
                            )
                          )
                          .filter(Boolean),
                      }
                    })
                    .filter((category: any) => category.habilitado)
                : []
            }
          })(),
        }
      })[0]
    )
  })

  useEffect(() => {
    dispatch(resetSearch())
  }, [])

  useFirestoreConnect(() => [
    {
      collection: 'comercios',
      where: [
        ['id', '==', commerceId],
        ['habilitado', '==', true],
      ],
    },
    {
      collection: 'categorias',
      where: [
        ['comercioId', '==', commerceId],
        ['habilitado', '==', true],
      ],
      populates: populatesCategories,
    },
    {
      collection: 'productos',
    },
  ])

  if (!isLoaded(commerce)) {
    return <MenuLoader height={240} />
  }

  if (isEmpty(commerce)) {
    return <Redirect to="/not-found" />
  }

  const renderTab = () => {
    switch (tab) {
      case 0:
        return (
          <CategoryList
            categories={commerce.categorias}
            commerceId={commerce.id}
            loading={loading}
          />
        )
      case 1:
        return <Commerce data={commerce} />
    }
  }

  const images: string[] =
    commerce?.imageProfile !== ''
      ? [commerce?.imageProfile, ...commerce?.imagenes]
      : [...commerce?.imagenes]

  return (
    <>
      <Helmet>
        <title>{commerce.nombre} | chefgo</title>
        <meta name="description" content={commerce?.descripcion} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />

        {/* Facebook card */}
        <meta property="og:title" content={`${commerce.nombre} | Chefgo`} />
        <meta property="og:description" content={commerce?.descripcion} />
        <meta
          property="og:url"
          content={`https://menu.chefgo.app/commerce/${commerceId}`}
        />
        <meta
          property="og:image"
          content="https://chefgo.app/assets/images/chefgo-facebook-card.png"
        />
        <meta property="og:image:alt" content={`${commerce.nombre} | Chefgo`} />

        {/* Twitter card */}
        <meta name="twitter:title" content={`${commerce.nombre} | Chefgo`} />
        <meta name="twitter:description" content={commerce?.descripcion} />
        <meta
          name="twitter:image:alt"
          content={`${commerce.nombre} | Chefgo`}
        />
      </Helmet>

      <ParallaxLayout
        title={
          <h1
            className={`font-bold text-white uppercase text-xl h-10 leading-10 truncate pr-2 ${
              tab === 0 ? 'mb-2' : ''
            }`}
          >
            {commerce?.nombre}
          </h1>
        }
        // aditionalHeader={
        //   tab === 1 ? (
        //     <>
        //       <p className="text-sm text-gray-200">
        //         <FiPhone className="inline" /> {commerce?.telefono}
        //       </p>
        //       <p className="text-sm text-gray-200">
        //         <FiMapPin className="inline" /> {commerce?.domicilio?.direccion}
        //       </p>
        //     </>
        //   ) : (
        //     <></>
        //   )
        // }
        images={images}
        input={
          tab === 0 ? (
            <SearchBar
              placeholder="Buscar producto"
              value={value}
              onChange={setValue}
              LeftIcon={RiRestaurantLine}
            />
          ) : null
        }
        height={240}
        brakePoint={tab === 0 ? 152 : 203}
        navbar={
          <Navbar
            right={
              <>
                <div className="h-10 flex items-center">
                  <Link
                    className="flex p-1 bg-red-A200 rounded-full border-solid border-2 border-white"
                    to={`/map/${commerceId}`}
                  >
                    <FiMapPin className="self-center text-white text-md" />
                  </Link>
                </div>
                {commerce?.whatsapp && (
                  <div className="h-10 flex items-center">
                    <a
                      className="ml-2 flex p-1 bg-green-500 rounded-full border-solid border-2 border-white"
                      href={`https://api.whatsapp.com/send?phone=${commerce.whatsapp}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <RiWhatsappLine className="self-center text-white text-md" />
                    </a>
                  </div>
                )}
                <div className="h-10 flex items-center">
                  <span
                    className="ml-2 flex p-1 bg-red-A200 rounded-full border-solid border-2 border-white"
                    onClick={() => setPanel(!panel)}
                  >
                    <RiShareLine className="self-center text-white text-md" />
                  </span>
                </div>
              </>
            }
          />
        }
        footer={
          <div className="bg-gray-300 rounded-t-lg overflow-hidden flex justify-around p-2">
            <RiRestaurantLine
              onClick={() => setTab(0)}
              className={`text-xl btn-primary ${tab === 0 ? '' : 'disable'}`}
            />
            <RiStore2Line
              onClick={() => setTab(1)}
              className={`text-xl btn-primary ${tab === 1 ? '' : 'disable'}`}
            />
          </div>
        }
        sharePanelState={panelState}
        shareMessage={`${t('share.menu.message1')} '${commerce.nombre}' ${t(
          'share.menu.message2'
        )}`}
        shareUrl={`${process.env.REACT_APP_HOST}/commerce/${commerceId}`}
      >
        <div className="pb-16">{renderTab()}</div>
      </ParallaxLayout>
    </>
  )
}

export default Menu
