import React, { FC, ReactElement } from 'react'

interface FooterInterfaceProps {
  content?: ReactElement
  classNames?: string
}

export const Footer: FC<FooterInterfaceProps> = ({ content, classNames }) => (
  <footer className={`w-full ${classNames}`}>{content}</footer>
)

export default Footer
