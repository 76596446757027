import React, { FC, ReactElement, useState } from 'react'
import { Footer, Header, ScrollTop, SharePanel } from 'app/components'

export enum ContentDirection {
  row = 'flex-row',
  col = 'flex-col',
}

interface LayoutInputType {
  header?: ReactElement
  headerImage?: string
  content?: ReactElement
  contentDirection?: ContentDirection
  children?: any
  footer?: ReactElement | any
  navbar?: ReactElement
  sharePanelState?: [boolean, (bool: boolean) => void]
  shareUrl?: string
  shareMessage?: string
}

export const Layout: FC<LayoutInputType> = ({
  header,
  headerImage,
  content,
  contentDirection = ContentDirection.col,
  children,
  footer,
  navbar,
  sharePanelState,
  shareUrl,
  shareMessage,
}) => {
  const [ref, setRef] = useState()
  const [reference, setReference] = useState()
  const [position, setPosition] = useState(0)

  return (
    <>
      <div className="container h-screen flex flex-col bg-white m-auto">
        {header && (
          <Header navbar={navbar} content={header} headerImage={headerImage} />
        )}
        <main
          className={`flex ${contentDirection} flex-1 overflow-y-scroll my-4 px-4`}
          ref={(el) => setRef(el)}
          onScroll={(e) => setPosition(ref?.scrollTop)}
        >
          <div ref={(el) => setReference(el)} />
          {content || children}
        </main>
        {footer && (
          <div className="fixed bottom-0 container">
            <Footer content={footer} />
          </div>
        )}
        {/*<ScrollTop position={position} reference={reference} />*/}
      </div>
      {sharePanelState && sharePanelState[0] && shareUrl && shareMessage && (
        <SharePanel
          onClose={() => sharePanelState[1](!sharePanelState[0])}
          url={shareUrl}
          message={shareMessage}
        />
      )}
    </>
  )
}

export default Layout
