import React, { useEffect, useState } from 'react'
import { storageService } from 'app/services'
import { get, set } from 'local-storage'

interface ThumbnailInputType {
  resource: string
}

export const Thumbnail = ({ resource }: ThumbnailInputType) => {
  const [url, setUrl] = useState()

  useEffect(() => {
    const persistedResource: string = get(resource)

    if (persistedResource) {
      setUrl(persistedResource)
    } else {
      storageService.getImage(resource).then((imgUrl: string) => {
        set(resource, imgUrl)
        setUrl(imgUrl)
      })
    }
  }, [])

  return url ? (
    <img
      className="w-12 h-12 md:w-20 md:h-20 bg-black mr-2 border-2 border-gray-300 rounded-sm"
      src={url}
      alt=""
    />
  ) : null
}

export default Thumbnail
