import { useState } from 'react'
import I18n from 'app/i18n'

export const useTranslation = () => {
  const [, setTranslations] = useState()

  const addTranslation = (json: {}) => {
    I18n.setConfig(I18n.locale, json)
    setTranslations(I18n.translations)
  }

  return {
    t: I18n.t,
    lang: I18n.locale,
    addTranslation,
  }
}

export default useTranslation
