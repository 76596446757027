import React from 'react'
import { Thumbnail } from 'app/components'
import { useSelector } from 'react-redux'

export const Product = ({
  commerceId,
  product,
}: {
  commerceId: string
  product: any
}) => {
  const commerce = useSelector(
    (state: any) =>
      state.firestore.data.comercios &&
      state.firestore.data.comercios[commerceId]
  )

  return (
    <>
      {product && (
        <div className="flex bg-white border-gray-300 border-2 rounded-md p-2 mb-1">
          <Thumbnail resource={product?.imagen} />
          <div className="flex-1">
            <div className="flex justify-between text-gray-800">
              <h5 className="uppercase flex-1 break-words">
                {product?.nombre}
              </h5>
              <span className="font-bold text-xs">{`${
                commerce?.currency
              } ${parseFloat(product?.precio).toFixed(2)}`}</span>
            </div>
            <p className="text-xs text-gray-600 flex-1 break-words">
              {product?.descripcion.length > 100
                ? product?.descripcion
                    .split('')
                    .filter((char: string, i: number) => i < 100)
                    .join('') + '...'
                : product?.descripcion}
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default Product
