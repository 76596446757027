import React from 'react'
import './App.css'
import { Redux } from 'app/store'
import { Main } from 'app/components'
import { BrowserRouter } from 'react-router-dom'

function App() {
  return (
    <Redux>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </Redux>
  )
}

export default App
