import React, { FC, useEffect, useState } from 'react'
import { storageService } from 'app/services'
import { detectAppleDevice } from 'app/helpers'
import { get, set } from 'local-storage'

interface ImageInterfaceProps {
  resource: string
  className?: string
  height?: number
}

export const Image: FC<ImageInterfaceProps> = ({
  resource,
  className,
  height,
}) => {
  const [url, setUrl] = useState()

  useEffect(() => {
    const persistedResource: string = get(resource)

    if (persistedResource) {
      setUrl(persistedResource)
    } else {
      storageService.getImage(resource).then((imgUrl: string) => {
        set(resource, imgUrl)
        setUrl(imgUrl)
      })
    }
  }, [])

  return (
    <div className="flex justify-center">
      <img
        className={`border-2 border-gray-300 rounded-md bg-gray-300 w-full ${className}`}
        style={{
          height: height ? `${height}px` : detectAppleDevice() ? '50%' : 'auto',
        }}
        src={url}
        alt=""
      />
    </div>
  )
}

export default Image
