import React from 'react'
import { useTranslation } from 'app/hooks'

export const NotFound = ({ history }: { history: any }) => {
  const { t } = useTranslation()

  return (
    <div className="container h-screen flex flex-col justify-center bg-white px-4 m-auto center">
      <div className="flex flex-col bg-white rounded-lg w-3/4 md:w-1/3 p-2 m-auto">
        <img
          className="w-1/2 h-auto m-auto"
          src={`${process.env.PUBLIC_URL}/assets/images/not-found.png`}
          alt="Resource not found"
        />
        <div className="mt-4">
          <h1 className="font-bold text-center">{t('notFound.title')}</h1>
          <p className="text-center text-gray-700">{t('notFound.message')}</p>
        </div>
        <div className="flex justify-around">
          <button
            className="uppercase mt-4 py-2 px-4 bg-red-A200 rounded-full text-white self-center"
            onClick={() => history.goBack()}
          >
            {t('notFound.back')}
          </button>
          <button
            className="uppercase mt-4 py-2 px-4 bg-red-A200 rounded-full text-white self-center"
            onClick={() => history.push('/')}
          >
            {t('notFound.search')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default NotFound
