import I18n from 'i18n-js'
import es from './locales/es'

const __DEV__ = process.env.NODE_ENV === 'development'

const defaultLocale = 'es'

const translationGetters: any = {
  es,
}

export const merge = (...args: any[]): any => {
  let target: any = {}

  let merger = (obj: any): void => {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          target[prop] = merge(target[prop], obj[prop])
        } else {
          target[prop] = obj[prop]
        }
      }
    }
  }

  for (let i = 0; i < args.length; i++) {
    merger(args[i])
  }

  return target
}

I18n.missingTranslation = (key: any) =>
  __DEV__ && console.log('missing translation: ' + key)

I18n.setConfig = (locale: string, external: {} = {}) => {
  I18n.translations[locale] = merge(
    I18n.translations[locale],
    translationGetters[locale],
    external
  )
  I18n.locale = locale
}

I18n.setConfig(defaultLocale)

export default I18n
