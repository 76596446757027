import React, { FC } from 'react'

interface DotsLoaderProps {
  text?: string
}

export const DotsLoader: FC<DotsLoaderProps> = ({ text }) => (
  <div className="w-full flex flex-col justify-center py-6">
    <div className="m-auto">
      <svg
        width={60}
        height={60}
        version="1.1"
        id="L5"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 0 0"
      >
        <circle fill="#ff5151" stroke="none" cx="10" cy="50" r="10">
          <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 15 ; 0 -15; 0 15"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle fill="#ff5151" stroke="none" cx="51" cy="50" r="10">
          <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 10 ; 0 -10; 0 10"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill="#ff5151" stroke="none" cx="90" cy="50" r="10">
          <animateTransform
            attributeName="transform"
            dur="1s"
            type="translate"
            values="0 5 ; 0 -5; 0 5"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
    </div>
    {text && <p className="text-center">{text}</p>}
  </div>
)

export default DotsLoader
