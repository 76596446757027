import React, { FC, ReactElement, useEffect, useState } from 'react'
import { storageService } from 'app/services'

interface HeaderInterfaceProps {
  navbar?: ReactElement
  content?: ReactElement
  headerImage?: string
  onlyImage?: boolean
}

export const Header: FC<HeaderInterfaceProps> = ({
  navbar,
  content,
  headerImage,
  onlyImage = false,
}) => {
  const [url, setUrl] = useState()

  useEffect(() => {
    storageService.getImage(headerImage).then((res: any) => setUrl(res))
  }, [headerImage])

  return (
    <div className="px-2">
      <header
        className="bg-red-A200 relative rounded-b-lg"
        style={{
          backgroundImage: `url('${url}')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div
          className="rounded-b-lg"
          style={{
            backgroundColor: url ? 'rgba(33,33,33,0.70)' : '#ff5151',
          }}
        >
          <div className={`h-8 px-2 ${onlyImage ? 'absolute w-full' : ''}`}>
            {navbar}
          </div>
          <div className={onlyImage ? '' : 'px-3 pb-3'}>
            {onlyImage && <img src={url} alt="url" className="rounded-b-lg" />}
            {!onlyImage && content}
          </div>
        </div>
      </header>
    </div>
  )
}

export default Header
