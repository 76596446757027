import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import ParallaxLayout from '../ParallaxLayout'
import CategoryLoader from './CategoryLoader'

const MenuLoader = ({ height }: { height: number }) => (
  <ParallaxLayout
    title={
      <h1 className="h-10 leading-1 mb-2">
        <SkeletonTheme color="#fafafa" highlightColor="#e2e8f0">
          <Skeleton
            className="rounded-md border-2 border-solid border-white inline-block"
            width="70%"
          />
        </SkeletonTheme>
      </h1>
    }
    input={
      <SkeletonTheme color="#fafafa" highlightColor="#e2e8f0">
        <Skeleton
          className="rounded-md border-2 border-solid border-white inline-block h-10"
          width="100%"
        />
      </SkeletonTheme>
    }
    height={height}
    footer={
      <div className="bg-gray-300 rounded-t-lg p-2 overflow-hidden flex justify-around pt-3 pb-6" />
    }
  >
    <div className={'pb-16'}>
      <CategoryLoader />
    </div>
  </ParallaxLayout>
)

export default MenuLoader
