import { combineReducers } from 'redux'
import { firebaseReducer as firebase } from 'react-redux-firebase'
import { firestoreReducer as firestore } from 'redux-firestore'
import { default as order } from './order/order.reducer'
import { default as search } from './search/search.reducer'

const createReducer = (asyncReducers) =>
  combineReducers({
    firebase,
    firestore,
    order,
    search,
    ...asyncReducers,
  })

export default createReducer
