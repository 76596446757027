export default {
  menu: {
    footer: {
      contact: 'contacto',
      contactDesc: 'contacto@chefgo.app',
      legal: 'legal',
      legalDesc: 'Condiciones de uso',
      copyright: 'Copyright © 2020 Chefgo. Todos los derechos reservados',
    },
    notFound: {
      title: 'No hay coincidencias!',
      message: 'No pudimos encontrar el producto que estas buscando',
      title2: 'No hay categorias!',
      message2: 'No pudimos encontrar categorías en el comercio',
      title3: 'No hay productos!',
      message3: 'No pudimos encontrar productos en el comercio',
      back: 'volver',
    },
  },
  home: {
    title: 'Buscador de locales',
    formText: 'Ingresa el código del local que estas buscando',
    error: 'El código ingresado no pertenece a ningún local adherido',
  },
  product: {
    price: 'Precio',
    about: 'Sobre el producto',
  },
  notFound: {
    title: 'Upsss!',
    message: 'No pudimos encontrar la página que estas buscando',
    back: 'volver',
    search: 'buscar',
  },
  share: {
    menu: {
      message1: 'Mirá la carta de',
      message2: 'en chefgo!',
    },
    product: {
      message1: 'Mirá este producto',
      message2: 'de',
      message3: 'en chefgo!',
    },
    title: 'Compartir',
    socialAccount: 'chefgoapp',
  },
}
