import React from 'react'
import { Layout, Navbar } from 'app/components'
import { DotsLoader } from 'app/components/loaders'
import { ContentDirection } from 'app/components/Layout'
import { FiArrowLeft, FiMapPin, FiPhone } from 'react-icons/fi'
import { RiStore2Line } from 'react-icons/ri'
import { useTranslation } from 'app/hooks'
import { Redirect, useHistory, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase'
import GoogleMapReact from 'google-map-react'

const Commerce = ({ text }) => (
  <div className="flex flex-col items-center">
    <span className="block bg-red-A200 rounded-full">
      <RiStore2Line className="text-white text-lg m-2" />
    </span>
    <small className="bg-white rounded-md p-2 mt-2 w-40 text-center font-bold">
      {text}
    </small>
  </div>
)

export const Map = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { commerceId } = useParams()

  const commerce = useSelector(
    ({ firestore }) =>
      firestore.data.comercios && firestore.data.comercios[commerceId]
  )

  useFirestoreConnect(() => [
    {
      collection: 'comercios',
      where: [
        ['id', '==', commerceId],
        ['habilitado', '==', true],
      ],
    },
  ])

  if (!isLoaded(commerce)) {
    return <DotsLoader />
  }

  if (isEmpty(commerce)) {
    return <Redirect to="/not-found" />
  }

  return (
    <Layout
      header={
        <>
          <h1 className="text-white text-center uppercase">
            {commerce?.nombre}
          </h1>
          <p className="text-center text-sm text-gray-200">
            <FiPhone className="inline" /> {commerce?.telefono}
          </p>
          <p className="text-center text-sm text-gray-200">
            <FiMapPin className="inline" /> {commerce?.domicilio?.direccion}
          </p>
        </>
      }
      headerImage={commerce?.imageProfile}
      navbar={
        <Navbar
          left={
            <span
              className="mr-2 flex p-1 bg-red-A200 rounded-full border-solid border-2 border-white"
              onClick={() => history.goBack()}
            >
              <FiArrowLeft className="self-center text-white text-md" />
            </span>
          }
        />
      }
      contentDirection={ContentDirection.row}
    >
      {commerce && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
          defaultCenter={{
            lat: commerce?.domicilio?.lat,
            lng: commerce?.domicilio?.long,
          }}
          defaultZoom={15}
        >
          <Commerce
            lat={commerce?.domicilio?.lat}
            lng={commerce?.domicilio?.long}
            text={commerce?.domicilio?.direccion}
          />
        </GoogleMapReact>
      )}
    </Layout>
  )
}

export default Map
