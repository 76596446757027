import React, { FC, ReactElement, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { ViewMore } from 'app/components'

interface CollapsibleProps {
  title: string
  subtitle?: string
  description?: string
  descriptionLength?: number
  forceOpen: boolean
  content?: ReactElement
}

export const Collapsible: FC<CollapsibleProps> = ({
  title,
  subtitle,
  description,
  descriptionLength,
  forceOpen,
  content,
  children,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <div
        className={`bg-gray-200 ${open ? 'rounded-t-lg' : 'rounded-lg'}`}
        onClick={() => setOpen(!open)}
      >
        <div className="flex justify-between items-center py-2 px-4 bg-gray-100 rounded-lg border-2 border-gray-200">
          <div className="flex flex-col">
            <span className="uppercase">{title}</span>
            <span className="text-xs text-gray-600">{subtitle}</span>
          </div>
          <FiChevronDown
            className={`text-red-A200 w-5 h-auto image-transition ${
              open ? 'image-collapsible-open' : ''
            }`}
          />
        </div>
      </div>
      <div
        className={`bg-gray-200 px-2 rounded-b-lg accordion-collapsed ${
          forceOpen || open ? 'accordion pt-2' : ''
        }`}
      >
        {description && (
          <ViewMore text={description} length={descriptionLength || 50} />
        )}
        {content || children}
      </div>
    </div>
  )
}

export default Collapsible
