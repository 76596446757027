import React, { FC, useState } from 'react'

interface CollapsibleProps {
  text: string
  length: number
}

export const Collapsible: FC<CollapsibleProps> = ({ text, length }) => {
  const [viewMore, setViewMore] = useState(false)

  return (
    <div className="flex mb-2">
      <p
        className={`flex-1 text-xs text-gray-600 ${
          viewMore ? 'overflow-visible' : 'overflow-hidden'
        } ${!viewMore && 'truncate'}`}
      >
        {text}
        <span
          className={`pl-2 text-xs text-gray-600 uppercase ${
            viewMore ? 'inline' : 'hidden'
          }`}
          onClick={() => setViewMore(!viewMore)}
        >
          ver menos
        </span>
      </p>
      <span
        className={`pl-2 text-xs text-gray-600 uppercase ${
          !viewMore && text.length > length ? 'inline' : 'hidden'
        }`}
        onClick={() => setViewMore(!viewMore)}
      >
        ver más
      </span>
    </div>
  )
}

export default Collapsible
