export const types = {
  SET_SEARCH: '[SEARCH] SET_SEARCH',
}

const setSearchAction = (value) => ({
  type: types.SET_SEARCH,
  value,
})

export const setSearch = (value) => (dispatch) =>
  dispatch(setSearchAction(value))

export const resetSearch = () => (dispatch) => dispatch(setSearchAction(''))

export default {
  setSearch,
  resetSearch,
}
