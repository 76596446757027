import React, { useState } from 'react'
import { Card, ParallaxLayout, Navbar, ViewMore } from 'app/components'
import { DotsLoader } from 'app/components/loaders'
import { useSelector } from 'react-redux'
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase'
import { Link, Redirect, useParams } from 'react-router-dom'
import { useTranslation } from 'app/hooks'
import { RiShareLine } from 'react-icons/ri'
import {
  GlutenFreeSVG,
  VeganSVG,
  VegetarianSVG,
  KosherSVG,
} from 'app/assets/icons'
import { FiArrowLeft } from 'react-icons/fi'
import Collapsible from '../components/Collapsible'

const services: { [key: string]: any } = {
  celiacos: {
    icon: GlutenFreeSVG,
    text: 'apto celiacos',
  },
  veganos: {
    icon: VeganSVG,
    text: 'apto veganos',
  },
  vegetarianos: {
    icon: VegetarianSVG,
    text: 'apto vegetarianos',
  },
  kosher: {
    icon: KosherSVG,
    text: 'certificado kosher',
  },
}

export const Product = () => {
  const { commerceId, productId } = useParams()
  const panelState = useState(false)
  const [showAllServices, setShowAllServices] = useState(false)
  const [panel, setPanel] = panelState
  const { t } = useTranslation()

  useFirestoreConnect(() => [
    {
      collection: 'comercios',
      where: [
        ['id', '==', commerceId],
        ['habilitado', '==', true],
      ],
    },
  ])

  useFirestoreConnect(() => [
    {
      collection: 'categorias',
      where: [
        ['comercioId', '==', commerceId],
        ['habilitado', '==', true],
      ],
    },
  ])

  useFirestoreConnect([
    {
      collection: 'productos',
      where: [
        ['id', '==', productId],
        ['habilitado', '==', true],
      ],
    },
  ])

  const commerce = useSelector(
    ({ firestore }: { firestore: any }) =>
      firestore.data.comercios && firestore.data.comercios[commerceId]
  )

  const product = useSelector(
    (state: any) =>
      state.firestore.data.productos &&
      state.firestore.data.productos[productId]
  )

  const categories = useSelector(
    ({ firestore }: { firestore: any }) => firestore.data.categorias
  )

  if (!isLoaded(commerce) || !isLoaded(categories) || !isLoaded(product)) {
    return <DotsLoader />
  }

  if (
    isEmpty(commerce) ||
    isEmpty(categories) ||
    isEmpty(product) ||
    !Object.keys(categories).includes(product.categoriaId)
  ) {
    return <Redirect to="/not-found" />
  }

  const images: string[] =
    product?.imagen !== ''
      ? [product?.imagen, ...product?.imagenes]
      : [...product?.imagenes]

  const getSelectionText = (max: number, min?: number) => {
    if (!min) return `(elige hasta ${max})`
    if (min === max) return `(elige ${max})`
    if (min + 1 === max) return `(elige ${min} o ${max})`
    if (min < max) return `(elige ${min} a ${max})`
  }

  return (
    <ParallaxLayout
      title={
        <h1
          className={`font-bold text-white uppercase text-xl h-10 leading-10 truncate pr-2 `}
        >
          {product?.nombre}
        </h1>
      }
      images={images}
      height={240}
      brakePoint={192}
      navbar={
        <Navbar
          left={
            <div className="h-10 flex items-center">
              <Link
                className={`mr-2 flex p-1 bg-red-A200 rounded-full border-solid border-2 border-white`}
                to={`/commerce/${commerceId}`}
              >
                <FiArrowLeft className="self-center text-white text-md" />
              </Link>
            </div>
          }
          right={
            <div className="h-10 flex items-center">
              <span
                className="flex p-1 bg-red-A200 rounded-full border-solid border-2 border-white"
                onClick={() => setPanel(!panel)}
              >
                <RiShareLine className="self-center text-white text-md" />
              </span>
            </div>
          }
        />
      }
      footer={
        <>
          {product?.precio && (
            <p className="bg-white p-2 flex md:hidden text-center text-xl text-gray-800 justify-center">
              {`${t('product.price')}: ${commerce?.currency}`}
              <span className="ml-2 inline-block font-bold">
                {parseFloat(product?.precio).toFixed(2)}
              </span>
            </p>
          )}
        </>
      }
      sharePanelState={panelState}
      shareMessage={`${t('share.product.message1')} '${product?.nombre}' ${t(
        'share.product.message2'
      )} '${commerce?.nombre}' ${t('share.product.message3')}`}
      shareUrl={`${process.env.REACT_APP_HOST}/commerce/${commerceId}/product/${productId}`}
    >
      <div className="pb-20">
        <div className="md:flex md:flex-row-reverse items-start">
          <div className="md:w-1/2">
            <Card
              className={product?.descripcion ? '' : 'hidden md:block'}
              header={<p>sobre el producto</p>}
              content={
                <>
                  {product?.precio && (
                    <p className="hidden md:flex text-3xl text-gray-800 mb-4">
                      {`${t('product.price')}: ${commerce?.currency || '$'}`}{' '}
                      <span className="ml-2 inline-block font-bold">
                        {parseFloat(product?.precio).toFixed(2)}
                      </span>
                    </p>
                  )}
                  {product?.descripcion && (
                    <div>
                      <ViewMore text={product.descripcion} length={50} />
                    </div>
                  )}
                </>
              }
            />

            {product?.apto &&
              Object.keys(product.apto).filter(
                (key: string) => product.apto[key]
              ).length !== 0 && (
                <Card
                  header={<p>apto</p>}
                  content={
                    <div className="flex flex-col">
                      {Object.keys(product.apto)
                        .filter((key: string) => services[key])
                        .filter((key: string) => product.apto[key])
                        .filter((key: string, i: number) =>
                          showAllServices ? true : i < 3
                        )
                        .map((key: string, i: number) => {
                          const Component = services[key]?.icon

                          return (
                            <div
                              key={i}
                              className="flex justify-between items-center m-2"
                            >
                              <Component
                                className={`w-6 h-6 ${
                                  product.apto[key]
                                    ? 'text-black'
                                    : 'text-gray-500'
                                } fill-current`}
                              />
                              <span className="text-base">
                                {services[key]?.text}
                              </span>
                            </div>
                          )
                        })}
                    </div>
                  }
                  footer={
                    <>
                      {Object.keys(product?.apto).filter(
                        (key: string) => product?.apto[key]
                      ).length > 3 && (
                        <button
                          className="bg-gray-300 p-2 rounded-md uppercase w-full"
                          onClick={() => setShowAllServices(!showAllServices)}
                        >
                          {showAllServices ? 'mostrar menos' : 'mostrar mas'}
                        </button>
                      )}
                    </>
                  }
                />
              )}
          </div>

          {product?.options &&
            product?.options?.filter((option: any) => {
              return (
                option.enabled &&
                option?.items.length > 0 &&
                option?.items.filter((item: any) => item.enabled).length > 0
              )
            }).length !== 0 && (
              <div className="w-full md:w-1/2 md:ml-3">
                {product?.options
                  ?.filter((option: any) => {
                    return (
                      option.enabled &&
                      option?.items.length > 0 &&
                      option?.items.filter((item: any) => item.enabled).length >
                        0
                    )
                  })
                  .map((option: any) => (
                    <div className="mt-2">
                      <Collapsible
                        key={option.name}
                        title={option.name}
                        subtitle={`${
                          option.required ? 'Obligatorio' : 'Opcional'
                        } ${getSelectionText(option.max, option?.min)}`}
                        description={option?.description}
                        forceOpen={false}
                        content={option?.items
                          .filter((item: any) => item.enabled)
                          .map((item: any, i: number) => (
                            <div
                              key={item.name}
                              className={`flex justify-between items-center py-2 ${
                                i ? 'border-t-2' : ''
                              }`}
                            >
                              <span>{item.name}</span>
                              {
                                <span>{`+ ${commerce?.currency || '$'} ${
                                  item?.price || 0
                                } c/u`}</span>
                              }
                            </div>
                          ))}
                      />
                    </div>
                  ))}
              </div>
            )}
        </div>
      </div>
    </ParallaxLayout>
  )
}

export default Product
