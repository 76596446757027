const initialState = {
  some: null,
}

export const orderReducer = (state = initialState, { type, ...payload }) => {
  switch (type) {
    case 'X':
      return {
        ...state,
        some: 'X',
      }
    case 'Y':
      return {
        ...state,
        some: 'Y',
      }
    case 'Z':
      return {
        ...state,
        some: 'Z',
      }
    default:
      return state
  }
}

export default orderReducer
