import React, { FC, useState } from 'react'
import { Card, Carousel } from 'app/components'
import {
  CreditCardSVG,
  DrinksSVG,
  GlutenFreeSVG,
  LanguagesSVG,
  MoneySVG,
  OutdoorSVG,
  ParkingSVG,
  SmokeSVG,
  SplitSVG,
  ValetParkingSVG,
  VeganSVG,
  VegetarianSVG,
  WheelChairSVG,
  WiFiSVG,
  KosherSVG,
} from 'app/assets/icons'
import Fade from 'react-reveal/Fade.js'

const paymentMethods: { [key: string]: any } = {
  credito: {
    icon: CreditCardSVG,
    text: 'tarjeta de credito',
  },
  debito: {
    icon: CreditCardSVG,
    text: 'tarjeta de debito',
  },
  efectivo: {
    icon: MoneySVG,
    text: 'efectivo',
  },
}

const services: { [key: string]: any } = {
  accesoDiscapacitados: {
    icon: WheelChairSVG,
    text: 'acceso discapacitados',
  },
  aireAcondicionado: {
    icon: SplitSVG,
    text: 'aire acondicionado',
  },
  aireLibre: {
    icon: OutdoorSVG,
    text: 'mesas al aire libre',
  },
  barra: {
    icon: DrinksSVG,
    text: 'servicio de barra',
  },
  celiacos: {
    icon: GlutenFreeSVG,
    text: 'apto celiacos',
  },
  estacionamiento: {
    icon: ParkingSVG,
    text: 'estacionamiento',
  },
  idiomas: {
    icon: LanguagesSVG,
    text: 'idiomas',
  },
  sectorFumadores: {
    icon: SmokeSVG,
    text: 'sector fumadores',
  },
  valetParking: {
    icon: ValetParkingSVG,
    text: 'estacionamiento asistido',
  },
  veganos: {
    icon: VeganSVG,
    text: 'apto veganos',
  },
  vegetarianos: {
    icon: VegetarianSVG,
    text: 'apto vegetarianos',
  },
  wifi: {
    icon: WiFiSVG,
    text: 'wifi gratis',
  },
  kosher: {
    icon: KosherSVG,
    text: 'certificado kosher',
  },
}

interface CommerceInterfaceProps {
  data: any
}

export const Commerce: FC<CommerceInterfaceProps> = ({ data }) => {
  const images =
    data?.imageProfile !== ''
      ? [data?.imageProfile, ...data?.imagenes]
      : [...data?.imagenes]
  const [showAllServices, setShowAllServices] = useState(false)
  const [showAllPaymentMethods, setShowAllPaymentMethods] = useState(false)

  return (
    <Fade right timeout={300}>
      <div className="md:flex md:flex-row-reverse items-start">
        {/*{images.length !== 0 && (*/}
        {/*  <div className="p-2 w-full md:w-1/2 md:ml-3">*/}
        {/*    <Card*/}
        {/*      className="w-full"*/}
        {/*      header={<p>imagenes</p>}*/}
        {/*      content={<Carousel images={images} />}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}

        <div className="md:w-1/2 p-2">
          {data?.descripcion && (
            <Card
              header={<p>descripción</p>}
              content={<p>{data.descripcion}</p>}
            />
          )}

          {data?.redWifi && data?.passWifi && (
            <Card
              header={<p>WiFi</p>}
              content={
                <>
                  <p>
                    <span className="font-bold">Nombre:</span> {data.redWifi}
                  </p>
                  <p>
                    <span className="font-bold">Contraseña:</span>{' '}
                    {data.passWifi}
                  </p>
                </>
              }
            />
          )}

          {data?.horarios.length !== 0 && (
            <Card
              header={<p>horarios</p>}
              content={
                <>
                  {data?.horarios.map((day: any, i: number) => (
                    <div key={i} className="flex justify-between text-sm">
                      <span>{day.dia}:</span>
                      <span>
                        {day?.intervalos
                          .map(
                            (int: any) =>
                              `${int?.horaDesde} a ${int?.horaHasta}`
                          )
                          .join('  /  ')}
                      </span>
                    </div>
                  ))}
                </>
              }
            />
          )}

          {Object.keys(data?.medioPago).filter(
            (key: string) => data?.medioPago[key]
          ).length !== 0 && (
            <Card
              header={<p>medios de pagos</p>}
              content={
                <div className="flex flex-col">
                  {Object.keys(data?.medioPago)
                    .filter((key: string) => data?.medioPago[key])
                    .filter((key: string, i: number) =>
                      showAllPaymentMethods ? true : i < 3
                    )
                    .map((key: string, i: number) => {
                      const Component = paymentMethods[key]?.icon

                      return (
                        <div
                          key={i}
                          className="flex justify-between items-center m-2"
                        >
                          <Component
                            className={`w-6 h-6 ${
                              data?.medioPago[key]
                                ? 'text-black'
                                : 'text-gray-500'
                            } fill-current`}
                          />
                          <span>{paymentMethods[key]?.text}</span>
                        </div>
                      )
                    })}
                </div>
              }
              footer={
                <>
                  {Object.keys(data?.medioPago).filter(
                    (key: string) => data?.medioPago[key]
                  ).length > 3 && (
                    <button
                      className="bg-gray-300 p-2 rounded-md uppercase w-full"
                      onClick={() =>
                        setShowAllPaymentMethods(!showAllPaymentMethods)
                      }
                    >
                      {showAllPaymentMethods ? 'mostrar menos' : 'mostrar mas'}
                    </button>
                  )}
                </>
              }
            />
          )}

          {Object.keys(data?.servicio).filter(
            (key: string) => data?.servicio[key]
          ).length !== 0 && (
            <Card
              header={<p>servicios</p>}
              content={
                <div className="flex flex-col">
                  {Object.keys(data?.servicio)
                    .filter((key: string) => services[key])
                    .filter((key: string) => data?.servicio[key])
                    .filter((key: string, i: number) =>
                      showAllServices ? true : i < 3
                    )
                    .map((key: string, i: number) => {
                      const Component = services[key]?.icon

                      return (
                        <div
                          key={i}
                          className="flex justify-between items-center m-2"
                        >
                          <Component
                            className={`w-6 h-6 ${
                              data?.servicio[key]
                                ? 'text-black'
                                : 'text-gray-500'
                            } fill-current`}
                          />
                          <span>{services[key]?.text}</span>
                        </div>
                      )
                    })}
                </div>
              }
              footer={
                <>
                  {Object.keys(data?.servicio).filter(
                    (key: string) => data?.servicio[key]
                  ).length > 3 && (
                    <button
                      className="bg-gray-300 p-2 rounded-md uppercase w-full"
                      onClick={() => setShowAllServices(!showAllServices)}
                    >
                      {showAllServices ? 'mostrar menos' : 'mostrar mas'}
                    </button>
                  )}
                </>
              }
            />
          )}
        </div>
      </div>
    </Fade>
  )
}

export default Commerce
