import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Layout from '../Layout'

const CategoryLoader = () => (
  <>
    {Array(7)
      .fill('')
      .map((x) => (
        <div
          className="h-12 mb-2 relative rounded-md"
          style={{ backgroundColor: '#e2e8f0' }}
        >
          <SkeletonTheme color="#fafafa" highlightColor="#e2e8f0">
            <Skeleton
              className="self-center inline-block h-6 m-3"
              width={200}
              circle
            />
            <Skeleton
              className="inline-block h-8 mb-2 absolute"
              style={{
                right: '13px',
                top: '13px',
              }}
              width={22}
              height={22}
              circle
            />
          </SkeletonTheme>
        </div>
      ))}
  </>
)

export default CategoryLoader
