import React, { FC, ReactElement } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { FiArrowLeft, FiHome } from 'react-icons/fi'

interface HeaderInterfaceProps {
  left?: ReactElement
  right?: ReactElement
  backButton?: boolean
  homeButton?: boolean
  buttonClass?: object
}

export const Navbar: FC<HeaderInterfaceProps> = ({
  left,
  right,
  backButton = false,
  homeButton = false,
  buttonClass,
}) => {
  const history: any = useHistory()
  const { commerceId } = useParams()

  return (
    <nav className="relative h-10">
      <div
        style={{ paddingTop: 2 }}
        className="flex absolute top-0 left-0 z-20"
      >
        {backButton && (
          <div className="h-10 flex items-center">
            <Link
              className={`mr-2 flex p-1 bg-red-A200 rounded-full border-solid border-2 border-white ${buttonClass}`}
              to={`/commerce/${commerceId}`}
            >
              <FiArrowLeft className="self-center text-white text-md" />
            </Link>
          </div>
        )}
        {homeButton && (
          <div className="h-10 flex items-center">
            <Link
              className={`mr-2 flex p-1 bg-red-A200 rounded-full border-solid border-2 border-white ${buttonClass}`}
              to="/"
            >
              <FiHome className="self-center text-white text-md" />
            </Link>
          </div>
        )}
        {left}
      </div>
      {/*<div className="w-full flex justify-center absolute top-0 left-0 right-0 z-10">*/}
      {/*  <a*/}
      {/*    href="https://chefgo.app"*/}
      {/*    className="underline-none text-sm px-3 pb-1 text-center bg-gray-100 rounded-b-lg border-b-2 border-l-2 border-r-2 border-red-A200 text-black"*/}
      {/*  >*/}
      {/*    powered by <span className="font-pacifico text-red-A200">chefgo</span>*/}
      {/*  </a>*/}
      {/*</div>*/}
      <div
        style={{ paddingTop: 2 }}
        className="flex absolute top-0 right-0 z-20"
      >
        {right}
      </div>
    </nav>
  )
}

export default Navbar
