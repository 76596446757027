import React, { FC, ReactElement, useRef, useState } from 'react'
import { Footer, SharePanel } from 'app/components'
import Carousel2 from './Carousel2'
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi'
// import { useSwipe } from 'app/hooks'

export enum ContentDirection {
  row = 'flex-row',
  col = 'flex-col',
}

interface LayoutInputType {
  title?: ReactElement
  aditionalHeader?: ReactElement
  images?: string[]
  input?: ReactElement | any
  height: number
  brakePoint?: number
  content?: ReactElement
  contentDirection?: ContentDirection
  children?: any
  footer?: ReactElement | any
  navbar?: ReactElement
  sharePanelState?: [boolean, (bool: boolean) => void]
  shareUrl?: string
  shareMessage?: string
}

export const Layout: FC<LayoutInputType> = ({
  title,
  aditionalHeader,
  images,
  input,
  height,
  brakePoint = 40,
  content,
  contentDirection = ContentDirection.col,
  children,
  footer,
  navbar,
  sharePanelState,
  shareUrl,
  shareMessage,
}) => {
  const [ref, setRef] = useState()
  const [position, setPosition] = useState(0)
  const carousel = useRef<any>()
  const hasImages = images && images.filter((img) => img !== '').length > 0

  return (
    <>
      <div
        className="container h-screen flex flex-col overflow-y-scroll bg-white m-auto"
        ref={(el) => setRef(el)}
        onScroll={(e) => setPosition(ref?.scrollTop)}
      >
        {title && (
          <div className="shadow-xl">
            <div
              className={`fixed top-0 container z-50 h-12 rounded-b-lg ${
                position > brakePoint ? 'bg-red-A200' : ''
              }`}
            >
              <div className={`h-10 px-2 absolute w-full`}>
                {position > brakePoint ? (
                  <div className="flex flex-row flex-no-wrap justify-between">
                    <div className="flex flex-1 flex-row truncate">
                      {navbar?.props?.left}
                      {title}
                    </div>
                    <div className="flex flex-row justify-between">
                      {navbar?.props?.right}
                    </div>
                  </div>
                ) : (
                  navbar
                )}
              </div>
              {input && position > brakePoint && (
                <div className="mt-10 p-2 bg-red-A200 rounded-b-lg shadow-xl">
                  <div className="rounded-md border-2 border-solid border-white">
                    {input}
                  </div>
                </div>
              )}
            </div>
            <div
              className="rounded-b-lg h-48 w-full relative"
              style={{
                minHeight: `${height}px`,
                overflow: 'hidden',
              }}
            >
              <div
                className="absolute top-0 left-0 right-0 rounded-b-lg flex flex-col justify-end h-full px-2 pb-2 z-40"
                style={{
                  background: hasImages
                    ? 'linear-gradient(0deg, #212121 0%, #424242 20%, transparent 100%)'
                    : '#ff5151',
                }}
              >
                {position < brakePoint && aditionalHeader}
                {title}
                {input && (
                  <div className="rounded-md border-2 border-solid border-white">
                    {input}
                  </div>
                )}
              </div>
              {images && images.length > 1 && (
                <div
                  className="flex items-center absolute right-0 pr-2 z-40"
                  style={{ top: '50px' }}
                >
                  <p className="inline-block text-sm bg-white rounded-full text-gray-900 h-6 w-6 m-2">
                    <FiArrowLeft
                      onClick={() => carousel.current.slickPrev()}
                      className="w-full h-full"
                    />
                  </p>
                  <p className="inline-block text-sm bg-white rounded-full text-gray-900 h-6 w-6">
                    <FiArrowRight
                      onClick={() => carousel.current.slickNext()}
                      className="w-full h-full"
                    />
                  </p>
                </div>
              )}
              {images && images.length > 0 && (
                <Carousel2
                  forwardRef={carousel}
                  className="absolute top-0 left-0 z-30"
                  slickNext={() => carousel.current.slickNext()}
                  slickPrev={() => carousel.current.slickPrev()}
                  slickGoTo0={() => carousel.current.slickGoTo(0)}
                  images={images}
                  height={height}
                />
              )}
            </div>
          </div>
        )}
        <main className={`flex ${contentDirection} flex-1 my-4 px-4`}>
          {content || children}
        </main>
        {footer && (
          <div className="fixed bottom-0 container">
            <Footer content={footer} />
          </div>
        )}
      </div>
      {sharePanelState && sharePanelState[0] && shareUrl && shareMessage && (
        <SharePanel
          onClose={() => sharePanelState[1](!sharePanelState[0])}
          url={shareUrl}
          message={shareMessage}
        />
      )}
    </>
  )
}

export default Layout
