import React from 'react'
import { Product } from 'app/components'
import { Link } from 'react-router-dom'

export const ProductList = ({
  commerceId,
  products,
}: {
  commerceId: string
  products: any
}) => (
  <>
    {products &&
      products
        ?.filter((product: any) => product.habilitado)
        .map((product: any) => (
          <Link
            key={product.id}
            className="underline-none text-color-inherit"
            to={`/commerce/${commerceId}/product/${product.id}`}
          >
            <Product commerceId={commerceId} product={product} />
          </Link>
        ))}
  </>
)

export default ProductList
