import * as firebase from 'firebase'

export const getImage = (resource) => {
  return new Promise((resolve) => {
    const storage = firebase.storage()
    const storageRef = storage.ref(resource)

    if (storageRef?.location?.path !== '') {
      storageRef
        .getDownloadURL()
        .then((url) => resolve(url))
        .catch(() => resolve(null))
    } else {
      resolve(null)
    }
  })
}

export default {
  getImage,
}
