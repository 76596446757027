import React, { FC } from 'react'
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import {
  RiFacebookCircleLine,
  RiMailSendLine,
  RiTelegramLine,
  RiTwitterLine,
  RiWhatsappLine,
} from 'react-icons/ri'
import { useTranslation } from 'app/hooks'
import Fade from 'react-reveal/Fade.js'
import Zoom from 'react-reveal/Zoom.js'

interface SharePanelProps {
  url: string
  message: string
  onClose: () => void
}

export const SharePanel: FC<SharePanelProps> = ({ url, message, onClose }) => {
  const { t } = useTranslation()

  return (
    <Zoom timeout={300}>
      <div className="flex flex-col justify-end fixed top-0 bottom-0 left-0 right-0 container h-full flex flex-col bg-black bg-opacity-75 m-auto z-50">
        <div className="flex-1" onClick={() => onClose()} />
        <Fade bottom timeout={800}>
          <div className="px-2">
            <div className="rounded-t-lg bg-white p-2">
              <h3 className="mb-4">{t('share.title')}</h3>
              <div className="flex justify-around flex-wrap">
                <WhatsappShareButton url={url} title={message} separator=": ">
                  <span
                    className="mr-2 flex p-1 bg-green-300 rounded-full border-solid border-2 border-white"
                    onClick={() => onClose()}
                  >
                    <RiWhatsappLine className="self-center text-white text-4xl" />
                  </span>
                </WhatsappShareButton>

                <TelegramShareButton url={url} title={message}>
                  <span
                    className="mr-2 flex p-1 bg-blue-500 rounded-full border-solid border-2 border-white"
                    onClick={() => onClose()}
                  >
                    <RiTelegramLine className="self-center text-white text-4xl" />
                  </span>
                </TelegramShareButton>

                <FacebookShareButton
                  url={url}
                  quote={message}
                  // hashtag="#chefgo"
                >
                  <span
                    className="mr-2 flex p-1 bg-blue-700 rounded-full border-solid border-2 border-white"
                    onClick={() => onClose()}
                  >
                    <RiFacebookCircleLine className="self-center text-white text-4xl" />
                  </span>
                </FacebookShareButton>

                {/*<FacebookMessengerShareButton*/}
                {/*  url={`${process.env.REACT_APP_HOST}/commerce/${commerceId}`}*/}
                {/*  appId="277567670313419"*/}
                {/*  redirectUri={`${process.env.REACT_APP_HOST}/commerce/${commerceId}`}*/}
                {/*  to=""*/}
                {/*>*/}
                {/*  <span className="mr-2 flex p-1 bg-red-A200 rounded-full border-solid border-2 border-white">*/}
                {/*    facebook messenger*/}
                {/*  </span>*/}
                {/*</FacebookMessengerShareButton>*/}

                <TwitterShareButton
                  url={url}
                  title={message}
                  via={t('share.socialAccount')}
                >
                  <span
                    className="mr-2 flex p-1 bg-blue-300 rounded-full"
                    onClick={() => onClose()}
                  >
                    <RiTwitterLine className="self-center text-white text-4xl" />
                  </span>
                </TwitterShareButton>

                <EmailShareButton
                  url={url}
                  subject={message}
                  body={`Accedé haciendo click en el sigueinte link`}
                  separator=": "
                >
                  <span
                    className="mr-2 flex p-1 bg-red-500 rounded-full"
                    onClick={() => onClose()}
                  >
                    <RiMailSendLine className="self-center text-white text-4xl" />
                  </span>
                </EmailShareButton>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </Zoom>
  )
}

export default SharePanel
