import { types } from '../../actions/search/search.actions'

const initialState = {
  value: '',
}

export const searchReducer = (state = initialState, { type, ...payload }) => {
  switch (type) {
    case types.SET_SEARCH:
      return {
        ...state,
        value: payload.value,
      }
    default:
      return state
  }
}

export default searchReducer
