import React, { FC } from 'react'
import { Category } from 'app/components'
import { useTranslation } from 'app/hooks'
import CategoryLoader from './loaders/CategoryLoader'
import { useSelector } from 'react-redux'

interface CategoryListProps {
  categories: [{}]
  commerceId: string
  loading: boolean
}

export const CategoryList: FC<CategoryListProps> = ({
  categories,
  commerceId,
  loading,
}) => {
  const { t } = useTranslation()
  const value = useSelector(({ search }: { search: any }) => search.value)
  const regExp = new RegExp(`^.*${value}.*$`, 'i')

  const filteredCategories =
    categories &&
    categories
      .map((category: any) => ({
        ...category,
        productos: category?.productos?.filter((product: any) =>
          product?.nombre.match(regExp)
        ),
      }))
      .filter((category: any) => category?.productos?.length)
  // .map((category: any) => ({
  //   ...category,
  //   productos: category?.productos?.filter(
  //     (product: any) => product?.habilitado && product?.nombre.match(regExp)
  //   ),
  // }))
  // .filter((category: any) => category?.productos?.length)

  // const where: any = () =>
  //   categories.map((category: any) => category.id).length
  //     ? [
  //         ['categoriaId', 'in', categories.map((category: any) => category.id)],
  //         ['habilitado', '==', true],
  //       ]
  //     : [['habilitado', '==', true]]
  //
  // useFirestoreConnect(() => [
  //   {
  //     collection: 'productos',
  //     where: where(),
  //   },
  // ])

  interface NotFoundProps {
    title: string
    text: string
  }

  const NotFound: FC<NotFoundProps> = ({ title, text }) => (
    <div className="flex flex-col bg-white rounded-lg w-3/4 md:w-1/3 p-2 m-auto">
      <img
        className="w-1/2 h-auto m-auto"
        src={`${process.env.PUBLIC_URL}/assets/images/not-found.png`}
        alt="Resource not found"
      />
      <div className="mt-4">
        <h1 className="font-bold text-center">{title}</h1>
        <p className="text-center text-gray-700">{text}</p>
      </div>
    </div>
  )

  const render = () => {
    if (loading) return <CategoryLoader /> // Loading categories
    if (!categories.length)
      return (
        <NotFound
          title={t('menu.notFound.title2')}
          text={t('menu.notFound.message2')}
        />
      )
    if (
      !categories
        .map((category: any) => Boolean(category.productos.length))
        .some(Boolean)
    )
      // Not Found categories
      return (
        <NotFound
          title={t('menu.notFound.title3')}
          text={t('menu.notFound.message3')}
        />
      )

    return filteredCategories?.length > 0 ? (
      <>
        {filteredCategories.map((category: any) => (
          <Category
            key={category.id}
            commerceId={commerceId}
            category={category}
            forceOpen={value !== '' ? true : false}
          />
        ))}
      </>
    ) : (
      <NotFound
        title={t('menu.notFound.title')}
        text={t('menu.notFound.message')}
      />
    )
  }

  return render()
}

export default CategoryList
